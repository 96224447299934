<template>
  <Top />
  <div class="main">
    <Transaction-navbar :title="lang.v.back" :CancelOrder="showRightType" />
    <div class="order-tips" v-if="!faceRecord.transactionGoods?.normal">
      <div>
        <span class="title" style="color: #f00">{{ lang.v.OrderErr }}</span>
        <div class="order-time">
          <span class="tips">{{ lang.v.CustomeRservice }}</span>
        </div>
      </div>
    </div>
    <div
      class="order-tips"
      v-else-if="faceRecord.orderStatus == 0 || faceRecord.orderStatus == 1"
    >
      <div>
        <span class="title">{{ lang.v.orderGenerated }}</span>
        <div class="order-time">
          <span class="tips">{{ lang.v.please }}</span>
          <span class="time">{{ orderTime }}</span>
          <span class="tips">{{ lang.v.paymentInformation }}</span>
        </div>
      </div>
      <div>
        <button class="err" @click="open">{{ lang.v.reportExceptions }}</button>
      </div>
    </div>
    <div class="order-tips" v-else-if="faceRecord.orderStatus == 2">
      <div>
        <span class="title">{{ lang.v.OrderOverTime }}</span>
        <div class="order-time">
          <span class="tips">{{ lang.v.CustomeRservice }}</span>
        </div>
      </div>
    </div>
    <div class="order-tips" v-else-if="faceRecord.orderStatus == 4">
      <span class="title">{{ lang.v.sellerToConfirm }}</span>
      <div class="order-time">
        <span class="tips">{{ lang.v.ordersCannotStage }}</span>
      </div>
    </div>
    <div class="order-tips" v-else-if="faceRecord.orderStatus == 6">
      <span class="title">{{ lang.v.orderCompleted }}</span>
      <div class="order-time">
        <span class="tips">{{ lang.v.contactustomer }}</span>
      </div>
    </div>
    <points-detailed
      :type="faceRecord.type"
      :transactionGoods="faceRecord.transactionGoods.commodityVO"
    ></points-detailed>
    <payment
      :payway="faceRecord.payWay"
      :payDesc="faceRecord.transactionGoods.commodityVO?.payDesc"
    />
    <Message-board />
  </div>
  <Transaction-submitv2
    :orderStatus="faceRecord.orderStatus"
    :required="getTransactionOrder"
    :orderData="faceRecord.transactionGoods"
  />
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router';
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import Top from '@/components/Top/top.vue';
import {
  fetchTransactionServeGoods,
  fetchTransactionServePay,
  fetchTransactionServeConfirm,
  fetchIMChat,
  fetchErrOrder,
  fetchOrderInfo,
} from '@/api/fetcher';
import message from '@/utils/message';
import dataCenter from '@/dataCenter';
import TransactionNavbar from '@/components/TransactionNavbar/index.vue';
import payment from '@/components/Payment/index.vue';
import PointsDetailed from '@/components/PointsDetailed/index.vue';
import MessageBoard from '@/components/MessageBoard/MessageBoard.vue';
import TransactionSubmitv2 from '@/components/TransactionSubmitv2/index.vue';
import { ElMessageBox } from 'element-plus';
import { lang } from '@/lang';

const route = useRoute();
const router = useRouter();
// 买家才展示取消订单按钮
const showRightType = ref(false);

const faceRecord = reactive({
  /** 订单信息 */
  transactionGoods: {},
  /** 商品类型 1：买 2：卖 */
  type: 2,
  /** 支付方式 */
  payWay: null,
  /** 订单创建时间 */
  createTime: 0,
  /** 时间限制 */
  timeLimit: 10,
  /** 订单状态 */
  orderStatus: 0,
  /** 禁止提交订单 */
  isClickDisabled: false,
});
// 获取订单信息
const getTransactionOrder = async () => {
  const data = await fetchOrderInfo({
    id: route.query.orderId,
  });
  if (data) {
    faceRecord.transactionGoods = data;
    faceRecord.createTime = data.updateTime;
    faceRecord.orderStatus = data.status;
    if (faceRecord.orderStatus == 4) {
      showRightType.value = false;
      faceRecord.isClickDisabled = true;
    }
  }
};

// 调启客服
const OnChat = async () => {
  const { data } = await fetchIMChat({
    orderId: faceRecord.transactionGoods.orderId,
  });
  router.push({
    path: '/im',
    query: {
      domain: data.data,
      group: 'group',
    },
  });
};
// 确认订单函数
const onsubmitOrder = async () => {
  if (faceRecord.type == 1) {
    const { data } = await fetchTransactionServePay({
      orderId: faceRecord.transactionGoods.orderId,
      version: faceRecord.transactionGoods.order.version,
    });
    if (data?.code == 0) {
      message.success(data.msg);
      await getTransactionGoods();
      showRightType.value = false;
    }
  } else {
    await getTransactionGoods();
    if (faceRecord.transactionGoods.order.status != 4) {
      return message.warning(lang.v.PleaseWaitPayment);
    }

    const { data } = await fetchTransactionServeConfirm({
      orderId: faceRecord.transactionGoods.orderId,
      version: faceRecord.transactionGoods.order.version,
    });
    if (data?.code == 0) {
      message.success(data.msg);
      await getTransactionGoods();
    }
  }
};

// 获取商品信息
const getTransactionGoods = async () => {
  const data = await fetchTransactionServeGoods({
    id: route.query.id,
  });
  if (data) {
    faceRecord.transactionGoods = data;
    const foundItem = dataCenter.payStatus.findDataPayStatus(route.query.id);
    console.log(foundItem);
    if (!foundItem?.payWay) {
      faceRecord.isClickDisabled = true;
    }
  }
};

const orderTime = ref('0');
// 计算和格式化时间
const formattedTime = () => {
  // 转换时限为毫秒
  const timeLimit = faceRecord.timeLimit * 60 * 1000;
  // 计算已经过去的时间
  const timeElapsed = Date.now() - new Date(faceRecord.createTime).getTime();

  // 计算剩余时间
  const timeRemaining = Math.max(timeLimit - timeElapsed, 0);
  const minutes = String(Math.floor(timeRemaining / 60000)).padStart(2, '0');
  const seconds = String(Math.floor((timeRemaining % 60000) / 1000)).padStart(
    2,
    '0'
  );
  return `${minutes}:${seconds}`;
};

// 上报异常
const open = async () => {
  const confirmResult = await ElMessageBox.confirm(
    lang.v.reportCorrect,
    'Warning',
    {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  );
  if (confirmResult === 'confirm') {
    try {
      // 发送请求
      await fetchErrOrder({
        orderId: faceRecord.transactionGoods?.id,
        version: faceRecord.transactionGoods?.version,
      });
      getTransactionOrder();
    } catch (error) {
      console.log(error);
    }
  }
};
// 设置定时器
let timer;
onMounted(async () => {
  faceRecord.type = route.query.type;
  faceRecord.payWay = route.query.payWay;
  showRightType.value = route.query.type == '2' ? true : false;
  faceRecord.timeLimit = dataCenter.payStatus.timeLimitMinutes;
  await getTransactionOrder();
  orderTime.value = formattedTime();
  timer = setInterval(() => {
    // 计算和更新剩余时间
    orderTime.value = formattedTime();
    if (orderTime.value == '00:00') {
      clearInterval(timer);
      getTransactionOrder();
    }
  }, 1000);
});
onUnmounted(() => clearInterval(timer));
</script>

<style lang="less">
.main {
  overflow-y: scroll;
  background: #f6f6f8;
  padding: 0.14rem;
  .order-tips {
    display: flex;
    justify-content: space-between;
    margin-top: 0.15rem;
    .err {
      color: #f00;
      border: 0.01rem solid #f00;
    }
    .title {
      font-weight: 600;
      font-size: 0.18rem;
      color: #222222;
      line-height: 0.21rem;
    }
    .order-time {
      margin-top: 0.11rem;
      font-weight: 400;
      .tips {
        font-size: 0.12rem;
        line-height: 0.14rem;
        color: #858c9f;
      }
      .time {
        margin: 0 0.06rem;
        font-size: 0.16rem;
        line-height: 0.19rem;
        color: #222222;
      }
    }
  }
  .order-tip {
    margin-top: 0.15rem;
    .err {
      color: #f00;
      border: 0.01rem solid #f00;
    }
    .title {
      font-weight: 600;
      font-size: 0.18rem;
      color: #222222;
      line-height: 0.21rem;
    }
    .order-time {
      margin-top: 0.11rem;
      font-weight: 400;
      .tips {
        font-size: 0.12rem;
        line-height: 0.14rem;
        color: #858c9f;
      }
      .time {
        margin: 0 0.06rem;
        font-size: 0.16rem;
        line-height: 0.19rem;
        color: #222222;
      }
    }
  }
}
</style>
